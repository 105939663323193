import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../../context";

export default function useGSheetDB() {
    const [state, setState] = useState({});
    const { errorAlert } = useGlobalContext();

    const getData = async () => {
        try {                       
            const res = await fetch("https://api.sheetbest.com/sheets/d1582424-a990-4727-9887-fea26f8d0f1d");
            const data = await res.json();
   
            return data;
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=sheetbest get= request error");
        }
    };

    const getSingleData = async ({ row }) => {
        try {
            const res = await fetch(
                `https://api.sheetbest.com/sheets/d1582424-a990-4727-9887-fea26f8d0f1d/${row}`
            );
            const data = await res.json();
            return data;
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=sheetbest get= request error");
        }
    };

    const addData = async ({ body, setIsLoading = ()=>{}, callback=()=>{} }) => {
        setIsLoading(true);
        try {
            const res = await fetch(`https://api.sheetbest.com/sheets/d1582424-a990-4727-9887-fea26f8d0f1d`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                },
                body: JSON.stringify(body),
            });
            const data = await res.json();
            console.log(data);
            callback(data)
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=sheetbest set= request error");
        }
        setIsLoading(false);
    };

    const updateData = async ({ row, body, setIsLoading = ()=>{}, callback=()=>{} }) => {
        setIsLoading(true);
        try {
            const res = await fetch(
                
                `https://api.sheetbest.com/sheets/d1582424-a990-4727-9887-fea26f8d0f1d/${row}`,
                // `https://api.sheetbest.com/sheets/52c32b2d-9e2e-402c-9a25-4bdc728582d8/${row}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-type": "application/json",
                    },
                    body: JSON.stringify(body),
                }
            );
            const data = await res.json();
            // console.log(data);
            callback(data)
        } catch (err) {
            errorAlert(err?.response?.data?.res_msg || "Internal Server Error");
            console.error(err, "=sheetbest set= request error");
        }
        setIsLoading(false);
    };

    return { getData, getSingleData, addData, updateData };
}
