import React, { useEffect, useState } from "react";
import { Field, Button, Select } from "../../../components";
import localData from "../../../localData";
import { useGlobalContext } from "../../../context";
import useGSheetDB from "../useGSheetDB";
import { set } from "date-fns";

export default function Reports() {
    const { sendDollar, handHoldingDollar, transferMoney } = localData.svgs;
    const { preloaderBig } = localData.images;
    const {
        getUpdateUserInfo,
        getLoadUserProfile,
        requestedData,
        isLoading,
        balance,
        formatNumber,
        refreshGSpreadSheetDB,setrefreshGSpreadSheetDB,
        requestedData: {
            userProfileData: { walletAddress, payPalEmailAddress },
        },
    } = useGlobalContext();

    const { getData, getSingleData, addData, updateData } = useGSheetDB();

    const [storedData, setStoredData] = useState([]);
    const [storedUser, setStoredUser] = useState({});
    const [matchingUser, setMatchingUser] = useState({});
    const [convertedBalance, setConvertedBalance] = useState(0);

    const init = async () => {
        // setStoredData
        const data = (await getData()) || [];
        if (data.length) setStoredData(data);

        // setMatchingUser
        const user = JSON.parse(localStorage.getItem("user"));
        setStoredUser(user);
        let matchingUser = {};
        data.filter((item, index) => {
            if (item.userId == user.user_id) {
                matchingUser = {
                    ...item,
                    index: index,
                };
            }
        });
        if (matchingUser) setMatchingUser(matchingUser);
        // console.log(matchingUser.withdrawal,' paypal-------------')
        // set balance
        const tempBalance = `$
        ${
            parseFloat(balance.data.total_revenue) > 0
                ? formatNumber(parseFloat(balance.data.total_revenue)?.toFixed(0) || "0")
                : 0
        }`;
        setConvertedBalance(tempBalance)
    };

    useEffect(() => {
        init();
    }, [refreshGSpreadSheetDB]);

    const [state, setState] = useState({
        wallet: "",
        chainTargeting: "",
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target || e;
        setState({ ...state, [name]: value });
    };

    const [isWalletLoading, setIsWalletLoading] = useState(false);

    const handleWalletSubmit = () => {
        if (Object.keys(matchingUser).length) {
            updateData({
                row: matchingUser.index,
                body: {
                    ...matchingUser,
                    // userId: storedUser.user_id,
                    wallet: state.wallet || matchingUser.wallet,
                    network: state.chainTargeting || matchingUser.network,
                    balance: convertedBalance,
                    // email: storedUser.email_address,
                    // name: storedUser.contact_name,
                    // balance: convertedBalance,
                },
                setIsLoading: setIsWalletLoading,
                callback: () => {
                    init();
                    setState({});
                    setChainTargetingOptions(
                        chainTargetingOptions.map((item) => ({ ...item, isActive: false }))
                    );
                },
            });
        } else {
            addData({
                body: {
                    userId: storedUser.user_id,
                    wallet: state.wallet,
                    network: state.chainTargeting,
                    email: storedUser.email_address,
                    name: storedUser.contact_name,
                    balance: convertedBalance,
                },
                setIsLoading: setIsWalletLoading,
                callback: () => {
                    init();
                    setState({});
                    setChainTargetingOptions(
                        chainTargetingOptions.map((item) => ({ ...item, isActive: false }))
                    );
                },
            });
        }
    };

    const [chainTargetingOptions, setChainTargetingOptions] = useState([
        {
            title: "Ethereum",
            CTR1: "0.52%",
            CTR2: "1.83%",
            CVR1: "0.98%",
            CVR2: "1.50%",
            size: "8000000",
            isActive: false,
            id: "5d508f5c-5114-4650-818d-d30716f15f0f",
        },
        {
            title: "Binance Smart Chain",
            CTR1: "0.64%",
            CTR2: "1.38%",
            CVR1: "0.82%",
            CVR2: "1.59%",
            size: "1440000",
            isActive: false,
            id: "0078d3de-9938-481c-9e44-cf8a0cbbe745",
        },
        {
            title: "Polygon",
            CTR1: "0.68%",
            CTR2: "1.99%",
            CVR1: "0.93%",
            CVR2: "1.89%",
            size: "2400000",
            isActive: false,
            id: "d88433b6-bce2-486e-a1c7-deb414c5d7d7",
        },
        {
            title: "Solana",
            CTR1: "0.98%",
            CTR2: "1.98%",
            CVR1: "0.65%",
            CVR2: "1.38%",
            size: "1800000",
            isActive: false,
            id: "cdcc132d-417d-4bb9-8014-4870bb7b23e9",
        },
        {
            title: "NEAR",
            CTR1: "0.78%",
            CTR2: "1.17%",
            CVR1: "0.92%",
            CVR2: "1.22%",
            size: "2400000",
            isActive: false,
            id: "ff569b16-e9de-41b2-b119-094ed5b062c0",
        },
        {
            title: "Arbitrum",
            CTR1: "0.57%",
            CTR2: "1.81%",
            CVR1: "0.57%",
            CVR2: "1.30%",
            size: "1200000",
            isActive: false,
            id: "19e17437-823e-4883-bd4e-11bc03661a29",
        },
        {
            title: "Optimism",
            CTR1: "0.55%",
            CTR2: "1.21%",
            CVR1: "0.60%",
            CVR2: "1.23%",
            size: "720000",
            isActive: false,
            id: "e1d46e78-6b1f-419e-9a03-a527626224e3",
        },
        {
            title: "Ripple",
            CTR1: "0.53%",
            CTR2: "1.15%",
            CVR1: "0.65%",
            CVR2: "1.53%",
            size: "1920000",
            isActive: false,
            id: "6a183a17-4c78-448e-bbc1-e7c9a6eec26f",
        },
        {
            title: "Cardano",
            CTR1: "0.74%",
            CTR2: "1.15%",
            CVR1: "0.64%",
            CVR2: "1.88%",
            size: "1680000",
            isActive: false,
            id: "bc8cb956-4248-4312-ad7a-c9f3f4f5ca42",
        },
        {
            title: "Tron",
            CTR1: "0.58%",
            CTR2: "1.86%",
            CVR1: "0.57%",
            CVR2: "1.49%",
            size: "2400000",
            isActive: false,
            id: "21618b57-3b1d-4076-bbfd-8a0ba08e3158",
        },
        {
            title: "Polkadot",
            CTR1: "0.96%",
            CTR2: "1.21%",
            CVR1: "0.67%",
            CVR2: "1.90%",
            size: "480000",
            isActive: false,
            id: "7b96779a-dc10-4c87-a13f-3eac7f3f579e",
        },
        {
            title: "Avalanche",
            CTR1: "0.53%",
            CTR2: "1.57%",
            CVR1: "0.76%",
            CVR2: "1.92%",
            size: "720000",
            isActive: false,
            id: "c14f2023-9f91-4863-9143-9f09e04c8f25",
        },
        {
            title: "Stellar",
            CTR1: "0.68%",
            CTR2: "1.82%",
            CVR1: "0.90%",
            CVR2: "1.92%",
            size: "1680000",
            isActive: false,
            id: "5b85d857-fb88-4856-9291-bb5f96474a80",
        },
        {
            title: "Chainlink",
            CTR1: "0.74%",
            CTR2: "1.75%",
            CVR1: "0.87%",
            CVR2: "1.34%",
            size: "240000",
            isActive: false,
            id: "2d9f97c2-6f66-455a-9f28-e701ab9cc126",
        },
        {
            title: "Monero",
            CTR1: "0.82%",
            CTR2: "1.73%",
            CVR1: "0.57%",
            CVR2: "1.48%",
            size: "192000",
            isActive: false,
            id: "0a66b93e-0a46-42ef-8c6e-cd26f5c09a0b",
        },
        {
            title: "Cosmos",
            CTR1: "0.84%",
            CTR2: "1.30%",
            CVR1: "0.86%",
            CVR2: "1.63%",
            size: "120000",
            isActive: false,
            id: "99b5a78d-6354-4e13-8c90-93f937841e7f",
        },
        {
            title: "Hedera",
            CTR1: "0.52%",
            CTR2: "1.51%",
            CVR1: "0.68%",
            CVR2: "1.76%",
            size: "120000",
            isActive: false,
            id: "e4897740-2f62-43de-a34a-3c16b96c5356",
        },
        {
            title: "Mantle",
            CTR1: "0.53%",
            CTR2: "1.39%",
            CVR1: "0.58%",
            CVR2: "1.18%",
            size: "60000",
            isActive: false,
            id: "1b6994f7-20b7-4853-bf2e-9216698ac684",
        },
        {
            title: "Aptos",
            CTR1: "0.53%",
            CTR2: "1.38%",
            CVR1: "0.81%",
            CVR2: "1.57%",
            size: "14400",
            isActive: false,
            id: "af910184-a094-4786-a5c7-4f8d11c5d3e1",
        },
        {
            title: "Cronos",
            CTR1: "0.63%",
            CTR2: "1.57%",
            CVR1: "0.98%",
            CVR2: "1.99%",
            size: "240000",
            isActive: false,
            id: "f0edcb35-0777-4001-8270-9dca9a75e170",
        },
        {
            title: "Algorand",
            CTR1: "0.88%",
            CTR2: "1.90%",
            CVR1: "0.63%",
            CVR2: "1.56%",
            size: "2400000",
            isActive: false,
            id: "a3327055-cf0d-479c-b1a2-7464a7ea533b",
        },
        {
            title: "Texos",
            CTR1: "0.87%",
            CTR2: "1.78%",
            CVR1: "0.51%",
            CVR2: "1.14%",
            size: "240000",
            isActive: false,
            id: "ad825187-0061-4f43-ae7b-631d60680e60",
        },
        {
            title: "Eos",
            CTR1: "0.82%",
            CTR2: "1.98%",
            CVR1: "0.66%",
            CVR2: "1.42%",
            size: "360000",
            isActive: false,
            id: "d2ec1678-af7a-4447-9b4a-43fc817a4124",
        },
        {
            title: "Bitcoin",
            CTR1: "0.51%",
            CTR2: "1.27%",
            CVR1: "0.91%",
            CVR2: "1.26%",
            size: "6400000",
            isActive: false,
            id: "79d50c1e-28db-4f39-87ff-0758a622c321",
        },
        {
            title: "Base",
            CTR1: "0.91%",
            CTR2: "1.40%",
            CVR1: "0.86%",
            CVR2: "1.47%",
            size: "480000",
            isActive: false,
            id: "0298369b-5129-430e-9b0b-9f71eee3531e",
        },
    ]);

    const handleSelectChange = (activeItem) => {
        // console.log(activeItem);
        setState((prev) => ({
            ...prev,
            chainTargeting: activeItem.title,
        }));
    };

    return (
        <div className="paypal">
            <h2 className="display-2">Payout details: </h2>
            <p className="text-style-1">
                Any commissions you earn will be denominated in US Dollars value and payout made in stable
                coin (USDT/USDC){" "}
            </p>
            <br />
            <br />

            <div className="paypal-form payouts-form">
                <Field
                    type="text"
                    label="Your wallet address:"
                    color="secondary"
                    required={true}
                    size="sm"
                    name={"wallet"}
                    value={state.wallet}
                    callback={handleOnChange}
                    className="payouts-field"
                    // className="horizontal-field"
                >
                    {/* <Button
                        variant="contained"
                        color="primary"
                        // onClick={handleSubmit}
                        onClick={handleWalletSubmit}
                        size="xs"
                        className={`wallet-btn`}
                        name="Update"
                        // disabled={state.wallet === walletAddress || isLoading.userInfoIsUpdating}
                        disabled={!state.wallet || state.wallet === matchingUser.wallet || isWalletLoading}
                        icon={
                            isWalletLoading ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                    /> */}
                </Field>

                <Select
                    items={chainTargetingOptions}
                    setItems={setChainTargetingOptions}
                    label="Network"
                    isLoading={false}
                    variant="outlined"
                    color="secondary"
                    className="payouts-select"
                    callback={(activeItem) => handleSelectChange(activeItem)}
                />
                <br />

                <Button
                    variant="contained"
                    color="primary"
                    // onClick={handleSubmit}
                    onClick={handleWalletSubmit}
                    // size="sm"
                    className={`wallet-btn payouts-btn`}
                    name="Update"
                    // disabled={state.wallet === walletAddress || isLoading.userInfoIsUpdating}
                    disabled={(!state.wallet && !state.chainTargeting) || isWalletLoading}
                    icon={
                        isWalletLoading ? (
                            <span className="endIcon">
                                <img src={preloaderBig} />
                            </span>
                        ) : null
                    }
                />
                <br />
                <br />
                {/* fjdk */}
                {/* <Field
                    type="text"
                    label="PayPal Email Address:"
                    color="secondary"
                    required={true}
                    size="sm"
                    name={"paypal"}
                    value={state.paypal}
                    callback={handleOnChange}
                    className="horizontal-field"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePaypalSubmit}
                        size="xs"
                        className={`paypal-btn`}
                        name="Update PayPal Email"
                        // disabled={state.paypal === payPalEmailAddress || isLoading.userInfoIsUpdating}
                        disabled={!state.paypal || state.paypal === payPalEmailAddress || isPaypalLoading}
                        icon={
                            isPaypalLoading ? (
                                <span className="endIcon">
                                    <img src={preloaderBig} />
                                </span>
                            ) : null
                        }
                    />
                </Field> */}
                {
                    <>
                        <br />
                        <div className="stored-user-details">
                            <label htmlFor="">Wallet address</label>
                            <p className="wallet-value">{matchingUser.wallet || "__"}</p>
                            <br />
                            <label htmlFor="">Network</label>
                            <p className="wallet-value">{matchingUser.network || "__"}</p>
                        </div>
                    </>
                }
            </div>
            <br />
            <br />

            <div className="paypal-banner">
                <h2 className="display-3">Your Payout Account</h2>
                <p className="text-style-1">
                    Within this section, you can access a comprehensive array of tools and features to manage,
                    customize, and monitor the various settings, preferences, and details tied to your Payout
                    account, ensuring seamless control and optimization of your financial transactions and
                    experiences.
                </p>
            </div>
            <br />
            <div className="paypal-cards-group">
                <div className="card paypal-card">
                    <div className="card-icon">{sendDollar}</div>
                    <div className="card-title">Commission Tracking</div>
                    <p className="card-description">
                        Track your earnings effortlessly. Stay updated on referral performance and
                        commissions.
                    </p>
                </div>
                <div className="card paypal-card">
                    <div className="card-icon">{handHoldingDollar}</div>
                    <div className="card-title">Payout History</div>
                    <p className="card-description">
                        Review past earnings and transactions easily. Access your complete payout history.
                    </p>
                </div>
                <div className="card paypal-card">
                    <div className="card-icon">{transferMoney}</div>
                    <div className="card-title"> Dashboard</div>
                    <p className="card-description text-primary">Monitor activity and analyze performance.</p>
                </div>
            </div>

            <div className="cover"></div>
        </div>
    );
}

// const CLIENT_ID = "849355758745-sm806b6qjr8guq013ahiq8r25em54g5t.apps.googleusercontent.com";
// const CLIENT_SECRET="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"
// const API_KEY = "AIzaSyDTyFCSLLx6FnD8k5JmPEBJHwKhs_bPSa0";
// const SCOPES = "https://www.googleapis.com/auth/spreadsheets";
