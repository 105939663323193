import React, { useEffect, useState, useRef } from "react";
import { Navbar, Button, Tabs, Modal } from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import ReferralProgram from "./tabs/ReferralProgram";
import PayPal from "./tabs/PayPal";
import Reports from "./tabs/Reports";

export default function Referral() {
    useEffect(() => {
        document.title = "Payouts";

        const metaDescription = document.querySelector('meta[name="description"]');
        // metaDescription.content =
        //     "Refer friends, get rewards! Join our referral program today.";
    }, []);
    
    const { requestedData: { userProfileData }, animations: {fade} } = useGlobalContext();
    const { referral, reports, paypal, users, pen } = localData.svgs;
    const { avatar } = localData.images;

    const tabs = [
        // {
        //     startIcon: referral,
        //     title: "Referral Program",
        //     content: <ReferralProgram userProfileData = {userProfileData}/>,
        // },
        {
            startIcon: reports,
            title: "Reports",
            content: <Reports/>,
        },
        {
            startIcon: paypal,
            title: "Payout Details",
            content: <PayPal/>,
        },
    
    ];


    return (
        <motion.main {...fade} className="main referral-page">
            <Navbar title="Payouts" />

            <div className="row">
                <h2 className="title display-2">Payout Details & Reports</h2>
                {/* <h4 className="description">
                Refer friends, get rewards! Join our referral program today.
                </h4> */}
            </div>
            

            <Tabs tabs={tabs}  />
        </motion.main>
    );
}
